import {combineReducers} from "redux";

export function error(state = null, action) {
    switch (action.type) {
        case "PLAYLIST_LIST_ERROR":
            return action.error;

        case "PLAYLIST_LIST_MERCURE_DELETED":
            return `${action.retrieved["@id"]} has been deleted by another user.`;

        case "PLAYLIST_LIST_RESET":
            return null;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case "PLAYLIST_LIST_LOADING":
            return action.loading;

        case "PLAYLIST_LIST_RESET":
            return false;

        default:
            return state;
    }
}

export function retrieved(state = null, action) {
    switch (action.type) {
        case "PLAYLIST_LIST_SUCCESS":
            const result = getNextPlaylistRefreshAndLayouts(action.retrieved["plannings"])
            action.retrieved["layoutsToDisplay"] = result["layouts"];
            action.retrieved["nextLayoutRefresh"] = result["nextLayoutRefresh"];
            return action.retrieved;

        case "PLAYLIST_LIST_RESET":
            return null;

        case "PLAYLIST_LIST_MERCURE_MESSAGE":
            console.log(action.retrieved)
            let retrieved = null
            if (action.retrieved["@context"] === "/api/contexts/Player") {
                retrieved = action.retrieved
            } else {
                let foundItemToReplace = false;
                retrieved = {
                    ...state,
                    "plannings": state["plannings"].map((item) => {
                            if (action.retrieved['@context'] === '/api/contexts/PlaylistLayout' || action.retrieved['@context'] === '/api/contexts/Layout') {
                                item['playlist']['layouts'] = item['playlist']['layouts'].map((layout) => {
                                    // playlist layout has to be modified
                                    if (action.retrieved['@context'] === '/api/contexts/PlaylistLayout') {
                                        if (layout["@id"] === action.retrieved["@id"]) {
                                            foundItemToReplace = true;
                                            return action.retrieved
                                        }
                                    }

                                    // layout (media) has to be modified
                                    if (action.retrieved['@context'] === '/api/contexts/Layout') {
                                        if (layout['layout']["@id"] === action.retrieved["@id"]) {
                                            layout['layout'] = action.retrieved
                                            foundItemToReplace = true;
                                        }
                                    }
                                    return layout;
                                })
                            }

                            // planning that has to be modified
                            if (action.retrieved['@context'] === '/api/contexts/Planning' && item["@id"] === action.retrieved["@id"]) {
                                return action.retrieved;
                            }

                            // playlist needs to be modified
                            if (action.retrieved['@context'] === '/api/contexts/Playlist' && item["playlist"]["@id"] === action.retrieved["@id"]) {
                                foundItemToReplace = true;
                                item["playlist"] = action.retrieved
                            }

                            return item;
                        }
                    ),
                };

                if (!foundItemToReplace) {
                    switch (action.retrieved['@context']) {
                        case "/api/contexts/Planning":
                            action.retrieved['player'].forEach((player) => {
                                if (player === retrieved['@id']) {
                                    retrieved['plannings'].push(action.retrieved)
                                }
                            })
                            break;
                        case "/api/contexts/Playlist":
                        case "/api/contexts/Layout":
                            // Since playlist & Layouts are created before plannings, we don't need to receive new
                            // playlists since we will receive them when they are associated to a planning
                            break;
                        case "/api/contexts/PlaylistLayout":
                            retrieved['plannings'].forEach(planning => {
                                if (planning['playlist']["@id"] === action.retrieved['playlist']) {
                                    planning['playlist']['layouts'].push(action.retrieved)
                                }
                            })
                            break;
                    }
                }
            }

            const resultUpdated = getNextPlaylistRefreshAndLayouts(retrieved["plannings"])
            retrieved["layoutsToDisplay"] = resultUpdated["layouts"];
            retrieved["nextLayoutRefresh"] = resultUpdated["nextLayoutRefresh"];
            console.log(retrieved)
            return retrieved;

        case "PLAYLIST_LIST_MERCURE_DELETED":
            const retrievedDelete = {
                ...state,
                "plannings": state["plannings"].filter(
                    (item) => item["@id"] !== action.retrieved["@id"]
                ),
            };

            retrievedDelete["plannings"].map((planning) => {
                planning['playlist']['layouts'] = planning['playlist']['layouts'].filter(
                    (item) => item["@id"] !== action.retrieved["@id"]
                )
            })

            const resultDeleted = getNextPlaylistRefreshAndLayouts(retrievedDelete["plannings"])
            retrievedDelete["layoutsToDisplay"] = resultDeleted["layouts"];
            retrievedDelete["nextLayoutRefresh"] = resultDeleted["nextLayoutRefresh"];
            return retrievedDelete;

        default:
            return state;
    }
}

export function eventSource(state = null, action) {
    switch (action.type) {
        case "PLAYLIST_LIST_MERCURE_OPEN":
            return action.eventSource;

        case "PLAYLIST_LIST_RESET":
            return null;

        default:
            return state;
    }
}

export default combineReducers({error, loading, retrieved, eventSource});

export function isToday(someDate) {
    const today = new Date()
    return someDate.getUTCDate() === today.getUTCDate() &&
        someDate.getUTCMonth() === today.getUTCMonth() &&
        someDate.getUTCFullYear() === today.getUTCFullYear()
}

export function getNextPlaylistRefreshAndLayouts(plannings) {
    console.log("Calculating next refresh")
    const currentDate = new Date();
    let nextRefresh = null;
    let layouts = [];

    plannings.forEach(planning => {
        if (!planning["active"]) {
            return;
        }

        let planningStartDate = new Date(planning["dateTimeStart"]);
        if (!planning["dateStart"]) {
            planningStartDate.setFullYear(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
        }

        let planningEndDate = new Date(planning["dateTimeEnd"]);
        if (!planning["dateEnd"]) {
            planningEndDate.setFullYear(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
        }

        // If a planning has a date in more than a day, only the hours are relevant
        if (planningEndDate > currentDate && !isToday(planningEndDate)) {
            planningEndDate.setFullYear(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
        }

        if (planningStartDate > currentDate && (nextRefresh === null || (planningStartDate - currentDate) < nextRefresh)) {
            nextRefresh = planningStartDate - currentDate;
        }

        if (planningEndDate > currentDate && (nextRefresh === null || (planningEndDate - currentDate) < nextRefresh)) {
            nextRefresh = planningEndDate - currentDate;
        }

        if (currentDate >= planningStartDate && currentDate <= planningEndDate) {
            layouts = layouts.concat(planning["playlist"]["layouts"]);
        }
        planning['playlist']['layouts'].forEach(async (layout) => {
            await fetch("https://res.cloudinary.com/boost-conseil/image/upload/" + layout.layout.link)
        })
    })
    return {"nextLayoutRefresh": nextRefresh, "layouts": layouts};
}
