import {Component} from "react";
import PropTypes, {func} from "prop-types";
import history from "../../history";

class LayoutDisplay extends Component {
    C_KEY = 67;

    static propTypes = {
        retrieved: PropTypes.object.isRequired,
        refreshLayouts: func.isRequired,
    }

    nextImageTimeout = null;
    refreshTimeoutId = null;

    constructor(props) {
        super(props);
        this.state = {
            displayedIndex: 0,
        }
        this.setNextImageIndex = this.setNextImageIndex.bind(this)
        this.setRefreshTimeout = this.setRefreshTimeout.bind(this)
    }

    componentDidMount() {
        document.addEventListener('keydown', this._handleKeyDown);
        this.setRefreshTimeout()

        if (this.props.retrieved["layoutsToDisplay"].length > 0) {
            this.prepareNextImage()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.retrieved !== this.props.retrieved) {
            clearTimeout(this.nextImageTimeout);
            clearTimeout(this.refreshTimeoutId);

            this.setRefreshTimeout()
            this.setState({displayedIndex: 0})

            if (this.props.retrieved["layoutsToDisplay"].length > 0) {
                this.prepareNextImage()
            }
        }
    }

    setRefreshTimeout() {
        const nextRefreshHour = new Date()
        console.log("Current time : " + nextRefreshHour)
        nextRefreshHour.setTime(nextRefreshHour.getTime() + this.props.retrieved["nextLayoutRefresh"])
        console.log("Next refresh planned at " + nextRefreshHour)
        // We refresh the layout list after closest start / end of playlists.
        // If no refresh is set because the function failed,
        // refresh after 30 minutes by default
        this.refreshTimeoutId = setTimeout(() => {
            console.log("refreshing layouts");
            this.props.refreshLayouts();
        }, this.props.retrieved["nextLayoutRefresh"] > 0 ? this.props.retrieved["nextLayoutRefresh"] : 1800000);

    }

    _handleKeyDown = (event) => {
        switch (event.keyCode) {
            case this.C_KEY:
                history.push("/")
                break;
            default:
                break;
        }
    }

    componentWillUnmount() {
        console.log("Unmounting and clearing timeouts");
        clearTimeout(this.refreshTimeoutId);
        clearTimeout(this.nextImageTimeout);
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    setNextImageIndex() {
        this.setState((prevState) => {
            const nextIndex = this.props.retrieved && prevState.displayedIndex < this.props.retrieved["layoutsToDisplay"].length - 1 ? prevState.displayedIndex + 1 : 0;

            this.prepareNextImage(this.props.retrieved["layoutsToDisplay"][nextIndex]["duration"] * 1000)

            return ({
                displayedIndex: nextIndex
            })
        })
    }

    prepareNextImage(timeBeforeNext = null) {
        // This is necessary so that when mercure sends a playlist update
        let index = this.props.retrieved["layoutsToDisplay"][this.state.displayedIndex ?? 0] ? this.state.displayedIndex : 0

        this.nextImageTimeout = setTimeout(this.setNextImageIndex, timeBeforeNext ?? this.props.retrieved["layoutsToDisplay"][index]["duration"] * 1000)
    }

    render() {
        const contentStyle = {
            backgroundColor: "white",
            height: "100vh",
            width: "100vw",
            position: "absolute",
            top: "0",
            left: "0",
        }

        const imageStyle = {
            objectFit: "contain",
            height: 100 + "%",
            width: 100 + "%",
        }

        const player = this.props.retrieved;

        let index = player["layoutsToDisplay"][this.state.displayedIndex ?? 0] ? this.state.displayedIndex : 0

        return (
            <div style={contentStyle}>
                {player && player["layoutsToDisplay"].length > 0 && (
                    <img style={imageStyle}
                         src={"https://res.cloudinary.com/boost-conseil/image/upload/" + player["layoutsToDisplay"][index]["layout"]["link"]}
                         alt="Test"/>
                )}
                {player && player["layoutsToDisplay"].length <= 0 && (
                    <div className={"text-center"}>
                        <h1>
                            Aucune playlist active.
                        </h1>
                        <h2>Veuillez créer une playlist et y ajouter des médias.</h2>
                    </div>
                )}
            </div>
        )
    }
}

export default LayoutDisplay;