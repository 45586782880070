import React from 'react';
import {createRoot} from 'react-dom/client';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {Provider} from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import thunk from 'redux-thunk';
import {reducer as form} from 'redux-form';
import {Route, Switch} from 'react-router-dom';
import history from './history';
import {ConnectedRouter, connectRouter, routerMiddleware} from 'connected-react-router';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
// Import your reducers and routes here
import playlist from './reducers/playlist/';
import playlistRoutes from './routes/playlist';
import Edit from './components/configuration/Edit';
import {Analytics} from '@vercel/analytics/react';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    combineReducers({
        router: connectRouter(history),
        form,
        /* Add your reducers here */
        playlist,
    }),
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
);

const root = createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/" component={Edit} strict={true} exact={true}/>
                {playlistRoutes}
                <Route render={() => <h1>Not Found</h1>}/>
            </Switch>
        </ConnectedRouter>
        <Analytics/>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
