import React, {Component} from "react";
import PropTypes from "prop-types";
import history from "../../history";

class Edit extends Component {
    static propTypes = {
        error: PropTypes.string,
    }

    constructor(props) {
        super(props);
        this.state = {
            clientId: localStorage.getItem('signage_client_id') ? localStorage.getItem('signage_client_id') : '',
            clientSecret: localStorage.getItem('signage_client_secret') ? localStorage.getItem('signage_client_secret') : '',
            endpoint: localStorage.getItem('signage_endpoint') ? localStorage.getItem('signage_endpoint') : process.env.REACT_APP_API_ENDPOINT,
        }
    }

    render() {
        return (
            <div className="container">
                <h1>Signage Client ID and Client Secret</h1>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    localStorage.setItem('signage_client_id', this.state.clientId);
                    localStorage.setItem('signage_client_secret', this.state.clientSecret);
                    localStorage.setItem('signage_endpoint', this.state.endpoint);
                    localStorage.removeItem('api_access_token');
                    history.push('/playlists/');
                }
                }>
                    <div className="row">
                        <div className="form-group col-6">
                            <div className="input-group col-sm-6 mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="client-id-label">Client ID</span>
                                </div>
                                <input type="text" name="clientId" value={this.state.clientId} className="form-control"
                                       aria-describedby="client-id-label"
                                       onChange={(e) => this.setState({clientId: e.target.value})}/>
                            </div>
                        </div>
                        <div className="form-group col-6">
                            <div className="input-group col-sm-6 mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Client Secret</span>
                                </div>
                                <input type="text" name="clientSecret" value={this.state.clientSecret}
                                       className="form-control"
                                       onChange={(e) => this.setState({clientSecret: e.target.value})}/>
                            </div>
                        </div>
                    </div>
                    <label>Endpoint</label>
                    <input type="text" name="clientSecret" value={this.state.endpoint} className="form-control"
                           onChange={(e) => this.setState({endpoint: e.target.value})}/>

                    {
                        this.props.location.state && this.props.location.state.error &&
                        <div className="alert alert-danger mt-2" role="alert">
                            <p>An error occured : {this.props.location.state.error.message}</p>
                        </div>
                    }
                    <div className="mt-2">
                        <input type="submit" value="Submit" className="btn btn-success"/>
                    </div>
                </form>
            </div>
        )
    }
}

export default (Edit);
