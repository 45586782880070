import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {list, reset} from "../../actions/playlist/list";
import LayoutDisplay from "./LayoutDisplay";
import {heartbeat} from "../../utils/dataAccess";

class List extends Component {
    static propTypes = {
        retrieved: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        error: PropTypes.string,
        eventSource: PropTypes.instanceOf(EventSource),
        deletedItem: PropTypes.object,
        list: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            layouts: [],
        }

        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
        this.interval = setInterval(() => {
            heartbeat().then(r => console.log(r)).catch(r => console.error(r));
        }, 1000 * 60 * 5); // 5 minutes in milliseconds
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.page !== prevProps.match.params.page) {
            this.props.list(
                this.props.match.params.page &&
                decodeURIComponent(this.props.match.params.page)
            );
        }
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
        clearInterval(this.interval)
    }

    loadData() {
        this.props.list();
    }

    render() {
        return (
            <div>

                {this.props.loading && (
                    <div className="alert alert-info">Loading...</div>
                )}
                {this.props.deletedItem && (
                    <div className="alert alert-success">
                        {this.props.deletedItem["@id"]} deleted.
                    </div>
                )}
                {this.props.error && (
                    <div className="alert alert-danger">{this.props.error}</div>
                )}

                {
                    this.props.retrieved &&
                    <LayoutDisplay retrieved={this.props.retrieved} refreshLayouts={this.loadData}/>
                }

                {this.pagination()}
            </div>
        );
    }

    pagination() {
        const view = this.props.retrieved && this.props.retrieved["hydra:view"];
        if (!view || !view["hydra:first"]) return;

        const {
            "hydra:first": first,
            "hydra:previous": previous,
            "hydra:next": next,
            "hydra:last": last,
        } = view;

        return (
            <nav aria-label="Page navigation">
                <Link
                    to="."
                    className={`btn btn-primary${previous ? "" : " disabled"}`}
                >
                    <span aria-hidden="true">&lArr;</span> First
                </Link>
                <Link
                    to={
                        !previous || previous === first ? "." : encodeURIComponent(previous)
                    }
                    className={`btn btn-primary${previous ? "" : " disabled"}`}
                >
                    <span aria-hidden="true">&larr;</span> Previous
                </Link>
                <Link
                    to={next ? encodeURIComponent(next) : "#"}
                    className={`btn btn-primary${next ? "" : " disabled"}`}
                >
                    Next <span aria-hidden="true">&rarr;</span>
                </Link>
                <Link
                    to={last ? encodeURIComponent(last) : "#"}
                    className={`btn btn-primary${next ? "" : " disabled"}`}
                >
                    Last <span aria-hidden="true">&rArr;</span>
                </Link>
            </nav>
        );
    }

    renderLinks = (type, items) => {
        if (Array.isArray(items)) {
            return items.map((item, i) => (
                <div key={i}>{this.renderLinks(type, item)}</div>
            ));
        }

        return (
            <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
        );
    };
}

const mapStateToProps = (state) => {
    const {
        retrieved,
        loading,
        error,
        eventSource,
        deletedItem,
    } = state.playlist.list;
    return {retrieved, loading, error, eventSource, deletedItem};
};

const mapDispatchToProps = (dispatch) => ({
    list: (page) => dispatch(list(page)),
    reset: (eventSource) => dispatch(reset(eventSource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
