import {ENTRYPOINT} from "../config/entrypoint";
import {SubmissionError} from "redux-form";
import get from "lodash/get";
import has from "lodash/has";
import mapValues from "lodash/mapValues";

const MIME_TYPE = "application/ld+json";

export async function heartbeat() {
    let token = await JSON.parse(localStorage.getItem('api_access_token'))

    if (token === null || token["expires_at"] < new Date().toJSON()) {
        let clientId = localStorage.getItem('signage_client_id') ? localStorage.getItem('signage_client_id') : process.env.REACT_APP_SIGNAGE_CLIENT_ID;
        let clientSecret = localStorage.getItem('signage_client_secret') ? localStorage.getItem('signage_client_secret') : process.env.REACT_APP_SIGNAGE_CLIENT_SECRET;
        token = await authenticate(clientId, clientSecret)
        localStorage.setItem('api_access_token', JSON.stringify(token))
    }

    let options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token["token_type"] + " " + token["access_token"]
        },
        body: JSON.stringify({})
    }

    return global.fetch(new URL(ENTRYPOINT + "heartbeat"), options).then((response) => {
        return response;
    });
}

export async function fetch(id, options = {}) {
    if ("undefined" === typeof options.headers) options.headers = new Headers();
    if (null === options.headers.get("Accept"))
        options.headers.set("Accept", MIME_TYPE);

    if (
        "undefined" !== options.body &&
        !(options.body instanceof FormData) &&
        null === options.headers.get("Content-Type")
    )
        options.headers.set("Content-Type", MIME_TYPE);

    let token = await JSON.parse(localStorage.getItem('api_access_token'))

    if (token === null || token["expires_at"] < new Date().toJSON()) {
        let clientId = localStorage.getItem('signage_client_id') ? localStorage.getItem('signage_client_id') : process.env.REACT_APP_SIGNAGE_CLIENT_ID;
        let clientSecret = localStorage.getItem('signage_client_secret') ? localStorage.getItem('signage_client_secret') : process.env.REACT_APP_SIGNAGE_CLIENT_SECRET;
        token = await authenticate(clientId, clientSecret)
        localStorage.setItem('api_access_token', JSON.stringify(token))
    }

    if (token !== null) options.headers.set("Authorization", token["token_type"] + " " + token["access_token"]);

    return global.fetch(new URL(id, ENTRYPOINT), options).then((response) => {
        if (response.ok) return response;

        return response.json().then(
            (json) => {
                const error =
                    json["hydra:description"] ||
                    json["hydra:title"] ||
                    "An error occurred.";
                if (!json.violations) throw Error(error);

                let errors = {_error: error};
                json.violations.forEach((violation) =>
                    errors[violation.propertyPath]
                        ? (errors[violation.propertyPath] +=
                            "\n" + errors[violation.propertyPath])
                        : (errors[violation.propertyPath] = violation.message)
                );

                throw new SubmissionError(errors);
            },
            () => {
                throw new Error(response.statusText || "An error occurred.");
            }
        );
    });
}

export function mercureSubscribe(url, topics) {
    topics.forEach((topic) => {
        url.searchParams.append("topic", new URL(topic, ENTRYPOINT).toString())
    });
    url.searchParams.append("topic", ENTRYPOINT + 'plannings/{id}')
    url.searchParams.append("topic", ENTRYPOINT + 'playlist_layouts/{id}')

    console.log(url)

    return new EventSource(url.toString());
}

export function normalize(data) {
    if (has(data, "hydra:member")) {
        // Normalize items in collections
        data["hydra:member"] = data["hydra:member"].map((item) => normalize(item));

        return data;
    }

    // Flatten nested documents
    return mapValues(data, value =>
        Array.isArray(value)
            ? value.map(v => {
                if (typeof v === 'string') {
                    return v
                }
                return normalize(v);
            })
            : value instanceof Object ? normalize(value)
                : get(value, '@id', value)
    );
}

export function extractHubURL(response) {
    const linkHeader = response.headers.get("Link");
    if (!linkHeader) return null;

    const matches = linkHeader.match(
        /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
    );

    return matches && matches[1] ? new URL(matches[1], ENTRYPOINT) : null;
}

async function authenticate(clientId, clientSecret) {
    let formdata = new FormData();
    formdata.append("grant_type", "client_credentials");
    formdata.append("client_id", clientId);
    formdata.append("client_secret", clientSecret);

    let requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    let token = null;

    let response = await global.fetch(ENTRYPOINT + "oauth/v2/token", requestOptions)
    token = await response.json()
    if (response.status === 401) throw new Error('Wrong credentials.')
    let expireAt = new Date()
    expireAt.setSeconds(expireAt.getSeconds() + token["expires_in"])
    token["expires_at"] = expireAt

    return token;
}

