import {extractHubURL, fetch, mercureSubscribe as subscribe, normalize,} from "../../utils/dataAccess";
import {ENTRYPOINT} from "../../config/entrypoint";
import history from "../../history";


export function error(error) {
    return {type: "PLAYLIST_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "PLAYLIST_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "PLAYLIST_LIST_SUCCESS", retrieved};
}

export function list(page = ENTRYPOINT + "players/me") {
    return (dispatch) => {
        dispatch(loading(true));
        dispatch(error(""));

        console.log("Loading player data ...")

        fetch(page)
            .then((response) =>
                response
                    .json()
                    .then((retrieved) => ({retrieved, hubURL: extractHubURL(response)}))
            )
            .then(({retrieved, hubURL}) => {
                retrieved = normalize(retrieved);
                dispatch(loading(false));
                dispatch(success(retrieved));
                console.log(retrieved)

                let topics = []

                topics.push(retrieved["@id"])
                if (hubURL && retrieved['plannings'].length)
                    retrieved['plannings'].map((i) => {
                        topics.push(i["@id"])
                        topics.push(i['playlist']['@id'])
                        topics.push(...i['playlist']['layouts'].map((j) => j['@id']))
                        topics.push(...i['playlist']['layouts'].map((j) => j['layout']['@id']))
                    })

                dispatch(
                    mercureSubscribe(
                        hubURL,
                        topics
                    )
                );

            })
            .catch((e) => {
                dispatch(loading(false));
                dispatch(error(e.message));
                const location = {
                    pathname: '/',
                    state: {
                        error: e
                    }
                }
                history.push(location)
            });
    };
}

export function reset(eventSource) {
    return (dispatch) => {
        if (eventSource) eventSource.close();

        dispatch({type: "PLAYLIST_LIST_RESET"});
    };
}

export function mercureSubscribe(hubURL, topics) {
    return (dispatch) => {
        const eventSource = subscribe(hubURL, topics);
        dispatch(mercureOpen(eventSource));
        eventSource.addEventListener("message", (event) =>
            dispatch(mercureMessage(normalize(JSON.parse(event.data))))
        );
    };
}

export function mercureOpen(eventSource) {
    return {type: "PLAYLIST_LIST_MERCURE_OPEN", eventSource};
}

export function mercureMessage(retrieved) {
    return (dispatch) => {
        if (1 === Object.keys(retrieved).length) {
            dispatch({type: "PLAYLIST_LIST_MERCURE_DELETED", retrieved});
            return;
        }

        dispatch({type: "PLAYLIST_LIST_MERCURE_MESSAGE", retrieved});
    };
}
